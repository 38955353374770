import Icon from '@/components/atoms/Icon/Icon';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * IAccordionContent Interface for the Accordion Content Item
 *
 * @interface
 */
export interface IAccordionContentItem {
  /** The header of the Accordion Content Item */
  header: string;
  /** The content of the Accordion Content Item */
  content?: string | React.ReactNode;
  /** Component is rendered open initially */
  startOpen?: boolean;
}

/**
 * IAccordionContent Interface for the Accordion Content Component
 *
 * @interface
 */
export interface IAccordionContent {
  /** An array of AccordionContent objects containing a header and content */
  content: IAccordionContentItem[];
  /** The classNames */
  className?: string;
}

/**
 * Accordion Content Accordion molecule for displaying Accordion Content
 *
 * @param {IAccordionContent} props - The props for the Accordion Content
 * @returns {React.FC<IAccordionContent>} AccordionContent Component
 */
const AccordionContent: React.FC<IAccordionContent> = ({
  content,
  className,
}) => {
  // Base Button Classes to apply to accordion buttons
  const baseButtonClass =
    'text-body4 w-full h-full font-petco inline-flex justify-between text-left font-bold focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus-400 items-center';

  return (
    <div
      data-testid="accordion-content"
      className={twMerge(
        clsx(
          'border-b-solid border-b border-b-neutral-300 border-t-solid border-t border-t-neutral-300',
          className
        )
      )}
    >
      {content.map((contentItem, index) => (
        <Disclosure
          defaultOpen={contentItem.startOpen}
          as="div"
          key={index}
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                data-testid={`accordion-content-disclosure-btn-${index}`}
                className={`${baseButtonClass} ${open ? 'pt-4 pb-1' : 'py-4'} `}
              >
                {contentItem.header}
                <Icon
                  icon={open ? 'arrowUp' : 'arrowDown'}
                  size={24}
                  colorType="base"
                  colorShade={400}
                />
              </Disclosure.Button>
              <Disclosure.Panel
                data-testid={`accordion-content-disclosure-panel-${index}`}
                className="whitespace-pre-line"
              >
                {/* If the content is a React Node, render it as is */}
                {typeof contentItem.content === 'object' && contentItem.content}
                {/* If the content is a string, render it as a Paragraph */}
                {typeof contentItem.content === 'string' && (
                  <Paragraph size="body4" className="pr-8 pb-4">
                    {contentItem.content}
                  </Paragraph>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default AccordionContent;
